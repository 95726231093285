import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import styled, { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get, isString } from "lodash";
import { ControlIcons } from "icons/ControlIcons";
import { getCurrentApplication } from "selectors/applicationSelectors";
import {
  getCurrentApplicationId,
  getCurrentPageId,
  getVisiblePageList,
} from "selectors/editorSelectors";
import { getSelectedAppThemeProperties } from "selectors/appThemingSelectors";
import { builderURL } from "ee/RouteBuilder";
import { Button, Input, Form, message, Row, Col, Divider } from "antd";
import { updateApplication } from "actions/applicationActions";
import { processTreeData, findMissingItems } from "utils/treeUtils";
import ImagerUploader from "./ImageUploader";
import SortableTree from "./sortableTree";
import {
  convertData,
  translateData,
  MENU_KEY,
  PAGE_KEY,
} from "./sortableTree/data";
import IconSelector from "./sortableTree/IconSelector";
import { exIconInfo } from "components/common/AntdIcon";
const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const Header = styled.div`
  display: flex;
  padding: 20px;
  position: sticky;
  top: 0px;
  background: #fff;
  padding-bottom: 20px;
  z-index: 1;
  button {
    margin-left: auto;
  }
  & > div {
    display: flex;
    align-items: center;
    h1 {
      margin: 0;
      font-size: 18px;
      color: ${(props) => props.theme.colors.text.heading};
      margin-left: 10px;
    }
  }
`;
const ContainerWrapper = styled.div`
  padding: 20px;
`;
const TreeContainer = styled.div`
  min-height: 200px;
  width: 65%;
  padding: 4rem 4rem;
  && .ant-tree .ant-tree-treenode {
    align-items: center;
  }
`;

const CloseIcon = ControlIcons.CLOSE_CONTROL;

const updateMenuTree = (pagesMap: any, newTree: any[]) => (node: any) => {
  let item: any;
  if (node.isPage) {
    if (pagesMap[node.pageId]) {
      item = {
        ...node,
        title: pagesMap[node.pageId].pageName,
      };
      pagesMap[node.pageId].visited = true;
    }
  } else if (node.children) {
    const children: any = [];
    node.children.forEach(updateMenuTree(pagesMap, children));
    item = {
      ...node,
      children,
    };
  } else {
    item = { ...node };
  }
  if (item) {
    newTree.push(item);
  }
};

const updateJsonPageId: any = (pagesMap: any, list: any[]) => {
  return list.map((item: any) => {
    if (item.children && !item.isPage) {
      // isFold
      return {
        ...item,
        children: updateJsonPageId(pagesMap, item.children),
        icon: exIconInfo(item.icon)
      };
    }
    return {
      ...item,
      pageName: get(pagesMap, [item.pageId, "pageName"], ""), // 页面名称使用最新的
      pageId: get(pagesMap, [item.pageId, "pageId"], ""), //页面组件
      icon: exIconInfo(item.icon)
    };
  });
};

function PagesEditor() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const applicationId = useSelector(getCurrentApplicationId) as string;
  const pageId = useSelector(getCurrentPageId);
  const appName = useSelector(getCurrentApplication)?.name;
  const currentLayout = useSelector(getCurrentApplication)?.viewerLayout;
  const currentApplicationPages = useSelector(
    (state) => state.entities.pageList.pages,
  );
  const pages = useSelector(getVisiblePageList);
  const appPrimaryColor = useSelector(getSelectedAppThemeProperties)?.colors
    .primaryColor;
  const mRef = useRef<any>("");
  const initState = useMemo(() => {
    let init = {
      logoUrl: "",
      name: "",
      color: appPrimaryColor,
      treeData: currentApplicationPages.map((p) => ({
        title: p.pageName,
        pageId: p.pageId,
        isPage: true,
      })),
      outsiderTree: [],
      logoInfo: {
        default: "",
        small: "",
      },
    };
    if (currentLayout) {
      try {
        const pagesMap = currentApplicationPages.reduce((a: any, c: any) => {
          a[c.pageId] = { ...c };
          return a;
        }, {});
        const current = JSON.parse(currentLayout);
        const _treeData = current.treeData;
        const _outsiderTree = current.outsiderTree;
        // 不使用页面池的时候，把旧的隐藏数据合并到目录数据中
        // const missingdata = findMissingItems(_treeData, _outsiderTree)?.map(
        //   (p: any) => ({
        //     ...p,
        //     isHidden: true,
        //   }),
        // );
        // _treeData = _treeData.concat(missingdata);
        init = {
          ...current,
          name: appName,
          treeData: updateJsonPageId(pagesMap, _treeData || []),
          outsiderTree: updateJsonPageId(pagesMap, _outsiderTree || []),
        };
      } catch (e) {
        console.log(e);
      }
    }
    return init;
  }, [currentLayout]);

  const [logoInfo, setLogoInfo] = useState(
    initState.logoInfo || {
      default: "",
      small: "",
    },
  );
  const [name, setName] = useState(initState.name || appName);
  const [color, setColor] = useState(initState.color);
  const [treeData, setTreeData] = useState<any>(initState.treeData);
  const [outsiderTree, setOutsiderTree] = useState<any>(initState.outsiderTree);
  useEffect(() => {
    const inittreedata = initState.treeData;
    const pageNamesMap = currentApplicationPages.reduce((a: any, c: any) => {
      a[c.pageId] = { ...c };
      return a;
    }, {});
    // update menu tree
    const newMenuTree: any = [];
    const newOuterTree: any = [];
    inittreedata.forEach(updateMenuTree(pageNamesMap, newMenuTree));
    outsiderTree.forEach(updateMenuTree(pageNamesMap, newOuterTree));
    const newPages = Object.values(pageNamesMap)
      .filter((p: any) => !p.visited)
      .map((p: any) => ({
        key: p.pageId,
        title: p.pageName,
        pageId: p.pageId,
        isPage: true,
        children: [],
      }));
    const _tree = newMenuTree.concat(newPages);
    setTreeData(_tree);
    setOutsiderTree(outsiderTree); // 页面池的数据
    initNewTree(_tree); // 无uuid的加上uuid的key
  }, [pages, currentLayout]);

  const initNewTree = (tree: any) => {
    processTreeData(tree);
  };

  const onClose = useCallback(() => {
    history.push(builderURL({ pageId }));
  }, [pageId]);

  const addRootNode = () => {
    mRef.current && mRef.current.handleAdd();
  };
  const _treedata = convertData(treeData, MENU_KEY);
  const _pooldata = translateData(outsiderTree, PAGE_KEY);
  const treeProps = {
    poolData: _pooldata,
    treeData: _treedata,
    mRef,
  };

  const onSubmit = () => {
    const res = mRef.current.handleSubmit();
    const data = {
      name: name,
      viewerLayout: JSON.stringify({
        color,
        logoUrl: initState.logoUrl,
        logoInfo,
        name,
        treeData: res.menuData,
        outsiderTree: res.outsiderTree,
      }),
    };
    dispatch(updateApplication(applicationId, data));
    message.success("保存成功");
  };

  const onImageUrlChange = (type: string, value: string) => {
    const _logoImage = {
      ...logoInfo,
      [type]: value,
    };
    setLogoInfo(_logoImage);
  };

  return (
    <Wrapper>
      <Header>
        <div className="flex justify-between w-full items-center">
          <div className="flex w-full items-center">
            <CloseIcon
              color={get(theme, "colors.text.heading")}
              height={20}
              onClick={onClose}
              width={20}
            />
            <h1>应用菜单编辑</h1>
          </div>
          <Button onClick={onSubmit} type="primary">
            保存配置
          </Button>
        </div>
      </Header>
      <ContainerWrapper>
        <div className="mb-4 mt-2 font-bold flex justify-between">
          应用导航logo
        </div>
        <div className="p-4">
          <Row gutter={24} style={{ maxWidth: 600 }}>
            <Col span={12}>
              <h4 className="mb-2">默认logo</h4>
              <div style={{ height: 127 }}>
                <ImagerUploader
                  data={logoInfo.default}
                  des="最大尺寸：192px*50px"
                  onDataChange={(v: string) => onImageUrlChange("default", v)}
                  title="将文件拖拽到这里或点击上传"
                />
              </div>
            </Col>
            <Col span={12} style={{ maxHeight: 140 }}>
              <h4 className="mb-2">缩略图logo</h4>
              <div style={{ height: 127 }}>
                <ImagerUploader
                  data={logoInfo.small}
                  des="最大尺寸：36px*30px"
                  onDataChange={(v: string) => onImageUrlChange("small", v)}
                  title="将文件拖拽到这里或点击上传"
                />
              </div>
            </Col>
          </Row>
          <div className="text-gray-400">(仅限PNG或JPG格式)</div>
        </div>
        <Form
          labelCol={{ span: 3 }}
          style={{ maxWidth: 800, marginBlock: 20 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item label="应用名称">
            <Input onChange={(e) => setName(e.target.value)} value={name} />
          </Form.Item>
        </Form>
        <Divider type="horizontal" />
        <div data-no-touch-simulate>
          <div className="mb-4 mt-2 font-bold flex justify-between bg-white">
            菜单导航
            <Button ghost onClick={addRootNode} type="primary">
              + 新建目录
            </Button>
          </div>
          <div>
            <SortableTree {...treeProps} />
          </div>
          <TreeContainer />
        </div>
      </ContainerWrapper>
    </Wrapper>
  );
}

export default PagesEditor;
