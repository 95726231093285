import React, { useCallback } from "react";
import { Flex } from "design-system";
import { useSelector } from "react-redux";

import history from "utils/history";
import { getCurrentPageId } from "ee/selectors/entitiesSelector";
import UIEntitySidebar from "pages/Editor/widgetSidebar/UIEntitySidebar";
import { widgetListURL } from "ee/RouteBuilder";
import { EDITOR_PANE_TEXTS } from "ee/constants/messages";
import SegmentAddHeader from "../components/SegmentAddHeader";
import { ApplicationPayload } from "ee/constants/ReduxActionConstants";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";

const AddWidgets = () => {
  const pageId = useSelector(getCurrentPageId) as string;
  const currentApplication: ApplicationPayload = useSelector(
    getCurrentApplication,
  )!;
  const closeButtonClickHandler = useCallback(() => {
    history.push(widgetListURL({ pageId }));
  }, [pageId]);

  return (
    <>
      {currentApplication?.type !== "module" && (
        <SegmentAddHeader
          onCloseClick={closeButtonClickHandler}
          titleMessage={EDITOR_PANE_TEXTS.widgets_create_tab_title}
        />
      )}
      <Flex flexDirection="column" gap="spaces-3" overflowX="scroll">
        <UIEntitySidebar isActive />
      </Flex>
    </>
  );
};

export default AddWidgets;
